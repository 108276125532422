import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Sidenav from './sidenav';
import closedeyeicon from './assets/closedeyeicon.png';


const Settings = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className='md:flex md:bg-white bg-[#E0ECF5]'>
      <Sidenav />
      <div className='bg-[#E0ECF5] w-full p-[3rem]'>

        <div className='md:flex justify-between'>
       
          <div className='w-full md:w-[260px] mb-5 h-[70px] bg-white rounded-full text-center p-3'>
            <p className='text-[30px] font-bold'>Settings</p>
          </div>

 <Link to="/profile">
          <div className='w-full md:w-[260px] h-[70px] bg-[#FF6600] rounded-full text-center p-3'>
            <p className='text-[30px] text-white font-bold'>Profile</p>
          </div>
</Link>
        </div>

        <br />

        <div className="overflow-x-auto bg-white p-[2rem] rounded-[20px]">
        <div className="flex flex-col md:flex-row w-full">
  {/* Change Password Link */}
  <Link to="/settings" className="w-full">
    <div className="border-b-[2px] border-[#FF6600] w-full">
      <p className="text-center text-[18px] md:text-[24px] font-bold text-[#FF6600] p-2 md:p-3">Change password</p>
    </div>
  </Link> 

<br />
  {/* Secure Account Link */}
  <Link to="/secureaccount" className="w-full">
    <div className="border-b-[2px] border-black opacity-40 w-full">
      <p className="text-center text-[18px] md:text-[24px] font-bold opacity-40 p-2 md:p-3">Secure account</p>
    </div>
  </Link>
</div>


          <br />

          <div className="w-full h-[30rem]">
            <br />
            {/* Password Input */}
            <div className="relative w-full max-w-[516px] mx-auto md:mx-0">
  <input
    type={showPassword ? 'text' : 'password'}
    className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem]"
    placeholder="Enter new password"
  />
  <img
    src={closedeyeicon}
    alt="Toggle password visibility"
    onClick={togglePasswordVisibility}
    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer w-[13px] h-[10px]"
  />
</div>

{/* Confirm Password Input */}
<div className="relative w-full max-w-[516px] mx-auto md:mx-0 mt-4">
  <input
    type={showPassword ? 'text' : 'password'}
    className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem]"
    placeholder="Confirm password"
  />
  <img
    src={closedeyeicon}
    alt="Toggle password visibility"
    onClick={togglePasswordVisibility}
    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer w-[13px] h-[10px]"
  />
</div>


<div className='flex justify-end mt-[17rem]'>
<button className=' bg-[#FF6600] w-[273px] h-[69px] rounded-[20px] text-[40px] font-bold text-white '>
            Save
            </button>
</div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
