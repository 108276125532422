import React from 'react'
import Mainnav from './mainnav'
import Footer from './footer'
import automateabillimg from './assets/Automate a bill TV and Custom 1 (2).png'


const ServicesUpcommingbills = () => {
  return (
    <div>
    <div  className="bg-[#FF6600] bg-opacity-10 h-full"> 
   <Mainnav />

   <div>
     <h1 className="w-full max-w-[811px] h-auto text-[40px] font-bold sm:text-[60px] lg:text-[90px] text-center mx-auto text-[#FF6600]">
     See Upcoming
     Bills
     </h1>
     <br />
  
     
   </div>

<img src={automateabillimg} alt=""  className='mx-auto'/>
   <br />
   <br />
   <br />
   <br />
   <br />

   <div className="bg-[#FF6600] w-full text-white py-16 p-[2rem] lg:p-[4rem]">
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[48px]'>What Does Upcoming Bills Mean?</h1>
    <p className='text-[28px] font-semibold opacity-80'>To automate a bill means to set a time and day when a particular bill will be payed. You can automate a bill to make it a one time payment or to make it a frequent payment. All you have to do is just pick a day, month, year and time and a bill has been automated. When a bill is then automated, it wil be paid according to the date set without you logging in into the platform. NOTE: If you don’t won’t to automat a bill there’s an option to just pay instantly.</p>
  </div>
  <br />
  <br />
  <br />
  <br />
</div>
   

   <Footer />
 </div>
 </div>
  )
}

export default ServicesUpcommingbills









