// import React, { useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import billicon from './assets/billpaymenticon.svg';
// import billactiveicon from './assets/billpaymenticonactive.png';
// import createbillicon from './assets/createabillicon.svg';
// import createbillactiveicon from './assets/createabilliconactive.png';
// import dashboardactiveicon from './assets/dashboardiconactive.svg';
// import dashboardicon from './assets/dashboardicon.png';
// import settingsicon from './assets/settingsicon.svg';
// import settingsactiveicon from './assets/settingsiconactive.png';
// import sidenavllogo from './assets/sidenavlogo.png';
// import profileicon from './assets/profileicon.svg';
// import profileactiveicon from './assets/profileactive.png';
// import navIcon from './assets/navicon.png';
// import closeIcon from './assets/closeicon.svg';
// import logouticon from './assets/logouticon.svg';
// import cardactive from './assets/cardactiveicon.png';
// import cardinactive from './assets/cardinactiveicon.png';

// const Sidenav = () => {
//   const location = useLocation();
//   const [isSidebarOpen, setSidebarOpen] = useState(false);

//   const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

//   const isActive = (path) => location.pathname === path;

//   return (
//     <div>
//       {/* Menu Icon Toggle Button */}
//     <button
//   className="md:hidden p-4 text-black m-2  border-4 bg-white border-black rounded-[20px]"
//   onClick={toggleSidebar}
// >
//   <img
//     src={isSidebarOpen ? closeIcon : navIcon}
//     alt="Toggle Sidebar"
//     className="w-8 h-8"
//   />
// </button>

//       {/* Overlay background when sidebar is open */}
//       {isSidebarOpen && (
//         <div
//           className="fixed inset-0 bg-white bg-opacity-90 z-40"
//           onClick={toggleSidebar} // Close sidebar when overlay is clicked
//         />
//       )}

//       {/* Sidebar Container */}
//       <div
//   className={`fixed top-0 left-0 h-full p-8 transition-all duration-500 ease-in-out z-50 transform ${
//     isSidebarOpen ? 'translate-x-0 bg-white shadow-lg' : '-translate-x-full'
//   } group md:relative md:translate-x-0 md:bg-transparent hover:lg:w-[18rem] hover:bg-white hover:shadow-lg transition-all duration-500`}
// >

//         <div className="relative z-10">
//           {/* Sidebar Logo */}
//           <Link to="/"   className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]">
//             <img src={sidenavllogo} alt="AutoBillsPro Logo" />
//             <span className="ml-2 font-medium text-lg block md:hidden md:group-hover:inline transition-all duration-300">
//               AutoBillsPro
//             </span>
//           </Link>

//           {/* Sidebar Links */}
//           <Link to="/dashboard"   className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]">
//             <img
//               src={isActive('/dashboard') ? dashboardactiveicon : dashboardicon}
//               alt="Dashboard Icon"
//               className=" transition-all duration-300  block  "
//             />
//             <span
//               className={`text-lg whitespace-nowrap md:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
//                 isActive('/dashboard') ? 'text-[#FF6600]' : 'text-black'
//              } hover:text-[#FF6600]`}
//             >
//               Dashboard
//             </span>
//           </Link>

//           {/* Bill Payments Icon and Text */}
//           <Link to="/bill-payment"   className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]">
//             <img
//               src={
//                 location.pathname === '/bill-payment' ||
//                 location.pathname === '/bill-payments-history'
//                   ? billactiveicon
//                   : billicon
//               }
//               alt="Bill Payments Icon"
//               className=" transition-all duration-300  block  "
//             />
//             <span
//               className={`text-lg whitespace-nowrap md:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
//                 location.pathname === '/bill-payment' ||
//                 location.pathname === '/bill-payments-history'
//                   ? 'text-[#FF6600]'
//                   : 'text-black'
//              } hover:text-[#FF6600]`}
//             >
//               Bill Payments
//             </span>
//           </Link>

//           {/* card Sidebar Links */}
//           <Link to="/card"   className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]">
//             <img
//               src={isActive('/card') ?  cardactive : cardinactive}
//               alt="Create a Bill Icon"
//               className=" transition-all duration-300  block  "
//             />
//             <span
//               className={`text-lg whitespace-nowrap md:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
//                 isActive('/card') ? 'text-[#FF6600]' : 'text-black'
//              } hover:text-[#FF6600]`}
//             >
//                 Cards
//             </span>
//           </Link>

//           {/* Additional Sidebar Links */}
//           <Link to="/create-bill"   className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]">
//             <img
//               src={isActive('/create-bill') ? createbillactiveicon : createbillicon}
//               alt="Create a Bill Icon"
//               className=" transition-all duration-300  block  "
//             />
//             <span
//               className={`text-lg whitespace-nowrap md:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
//                 isActive('/create-bill') ? 'text-[#FF6600]' : 'text-black'
//              } hover:text-[#FF6600]`}
//             >
//               Create a Bill
//             </span>
//           </Link>

//           <Link to="/profile"   className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]">
//             <img
//               src={isActive('/profile') ? profileactiveicon : profileicon}
//               alt="Profile Icon"
//               className=" transition-all duration-300  block  "
//             />
//             <span
//               className={`text-lg whitespace-nowrap md:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
//                 isActive('/profile') ? 'text-[#FF6600]' : 'text-black'
//              } hover:text-[#FF6600]`}
//             >
//               Profile
//             </span>
//           </Link>

//           <Link to="/settings"   className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]">
//             <img
//               src={
//                 location.pathname === '/settings' ||
//                 location.pathname === '/secureaccount'
//                   ? settingsactiveicon
//                   : settingsicon
//               }
//               alt="Settings Icon"
//               className=" transition-all duration-300  block  "
//             />
//             <span
//               className={`text-lg whitespace-nowrap md:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
//                 location.pathname === '/settings' ||
//                 location.pathname === '/secureaccount'
//                   ? 'text-[#FF6600]'
//                   : 'text-black'
//              } hover:text-[#FF6600]`}
//             >
//               Settings
//             </span>
//           </Link>

//           <Link to="/"   className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]">
//             <img
//               src={logouticon}
//               className=" transition-all duration-300 h-[35px] w-[35px]"
//             />
//             <span
//               className='text-lg block md:hidden md:group-hover:inline transition-all duration-300 text-[black]'
//             >
//               logout
//             </span>
//           </Link>

//         </div>
//       </div>
//     </div>
//   );
// };

// export default Sidenav;

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import billicon from "./assets/billpaymenticon.svg";
import billactiveicon from "./assets/billpaymenticonactive.png";
import createbillicon from "./assets/createabillicon.svg";
import createbillactiveicon from "./assets/createabilliconactive.png";
import dashboardactiveicon from "./assets/dashboardiconactive.svg";
import dashboardicon from "./assets/dashboardicon.png";
import settingsicon from "./assets/settingsicon.svg";
import settingsactiveicon from "./assets/settingsiconactive.png";
import sidenavllogo from "./assets/sidenavlogo.png";
import profileicon from "./assets/profileicon.svg";
import profileactiveicon from "./assets/profileactive.png";
import navIcon from "./assets/navicon.svg";
import closeIcon from "./assets/closeicon.svg";
import logouticon from "./assets/logouticon.svg";
import cardactive from "./assets/cardactiveicon.png";
import cardinactive from "./assets/cardinactiveicon.png";
import whitewithorange from "./assets/AutoBillsPro Logo(white with orange).svg";

const Sidenav = () => {
  const location = useLocation();
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  const isActive = (path) => location.pathname === path;

  return (
    <div className="bg-[#FF6600] p-2 mb-7 md:bg-transparent ">
      {/* Menu Icon Toggle Button */}
      <div className="flex justify-between">
        <img
          src={whitewithorange}
          alt=""
          className="block md:hidden w-16 h-16 my-auto"
        />
        <button
          className="md:hidden p-4 text-black m-2   rounded-[20px]"
          onClick={toggleSidebar}
        >
          <img
            src={isSidebarOpen ? closeIcon : navIcon}
            alt="Toggle Sidebar"
            className="w-8 h-8"
          />
        </button>
      </div>

      {/* Overlay background when sidebar is open */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-white bg-opacity-90 z-40"
          onClick={toggleSidebar} // Close sidebar when overlay is clicked
        />
      )}

      {/* Sidebar Container */}
      <div
        className={`fixed top-0 left-0 h-full p-8 transition-all duration-500 ease-in-out z-50 transform ${
          isSidebarOpen
            ? "translate-x-0 bg-white shadow-lg"
            : "-translate-x-full"
        } group md:relative md:translate-x-0 md:bg-transparent hover:lg:w-[18rem] hover:bg-white hover:shadow-lg transition-all duration-500`}
      >
        <div className="relative z-10">
          {/* Sidebar Logo */}
          <Link
            to="/"
            className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]"
          >
            <img src={sidenavllogo} alt="AutoBillsPro Logo" />
            <span className="ml-2 font-medium text-lg block md:hidden md:group-hover:inline transition-all duration-300">
              AutoBillsPro
            </span>
          </Link>

          {/* Sidebar Links */}
          <Link
            to="/dashboard"
            className="flex items-center mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]"
          >
            {/* Icon */}
            <img
              src={isActive("/dashboard") ? dashboardactiveicon : dashboardicon}
              alt="Dashboard Icon"
              className="transition-all duration-300 group-hover:opacity-100 hover:opacity-0 "
            />
            <img
              src={dashboardactiveicon}
              alt="Dashboard Active Icon"
              className="transition-all duration-300 opacity-0 hidden hover:block hover:opacity-100"
            />

            {/* Text */}
            <span
              className={`text-lgmd:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
                isActive("/dashboard") ? "text-[#FF6600]" : "text-black"
              } hover:text-[#FF6600]`}
            >
              Dashboard
            </span>
          </Link>

          {/* Bill Payments Icon and Text */}
          <Link
            to="/bill-payment"
            className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]"
          >
            <img
              src={
                location.pathname === "/bill-payment" ||
                location.pathname === "/bill-payments-history"
                  ? billactiveicon
                  : billicon
              }
              alt="Bill Payments Icon"
              className=" transition-all duration-300  block  "
            />
            <img src={billactiveicon} alt="" className="hidden hover:block" />

            <span
              className={`text-lg whitespace-nowrap md:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
                location.pathname === "/bill-payment" ||
                location.pathname === "/bill-payments-history"
                  ? "text-[#FF6600]"
                  : "text-black"
              } hover:text-[#FF6600]`}
            >
              Bill Payments
            </span>
          </Link>

          {/* card Sidebar Links */}
          <Link
            to="/card"
            className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]"
          >
            <img
              src={isActive("/card") ? cardactive : cardinactive}
              alt="Create a Bill Icon"
              className=" transition-all duration-300  block  "
            />
            <span
              className={`text-lg whitespace-nowrap md:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
                isActive("/card") ? "text-[#FF6600]" : "text-black"
              } hover:text-[#FF6600]`}
            >
              Cards
            </span>
          </Link>

          {/* Additional Sidebar Links */}
          <Link
            to="/create-bill"
            className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem] group"
          >
            <img
              src={
                isActive("/create-bill") ? createbillactiveicon : createbillicon
              }
              alt="Create a Bill Icon"
              className=" transition-all duration-300  block "
            />
            <span
              className={`text-lg whitespace-nowrap md:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
                isActive("/create-bill") ? "text-[#FF6600]" : "text-black"
              } hover:text-[#FF6600]`}
            >
              Create a Bill
            </span>
          </Link>

          <Link
            to="/profile"
            className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]"
          >
            <img
              src={isActive("/profile") ? profileactiveicon : profileicon}
              alt="Profile Icon"
              className=" transition-all duration-300  block  "
            />
            <span
              className={`text-lg whitespace-nowrap md:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
                isActive("/profile") ? "text-[#FF6600]" : "text-black"
              } hover:text-[#FF6600]`}
            >
              Profile
            </span>
          </Link>

          <Link
            to="/settings"
            className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]"
          >
            <img
              src={
                location.pathname === "/settings" ||
                location.pathname === "/secureaccount"
                  ? settingsactiveicon
                  : settingsicon
              }
              alt="Settings Icon"
              className=" transition-all duration-300  block  "
            />
            <span
              className={`text-lg whitespace-nowrap md:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300 ${
                location.pathname === "/settings" ||
                location.pathname === "/secureaccount"
                  ? "text-[#FF6600]"
                  : "text-black"
              } hover:text-[#FF6600]`}
            >
              Settings
            </span>
          </Link>

          <Link
            to="/"
            className="flex justify-betw mb-14 group transition-all duration-300 md:w-[3rem] md:hover:w-[15rem]"
          >
            <img
              src={logouticon}
              className=" transition-all duration-300 h-[35px] w-[35px]"
              alt="Logout icon"
            />
            <span className="text-lgmd:inline-block md:translate-x-[-100%] md:opacity-0 md:group-hover:translate-x-0 md:group-hover:opacity-100 transition-all duration-300">
              logout
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidenav;
