import React from 'react'
import { useNavigate } from 'react-router-dom';
import arrowicon from './assets/arrowicon.png';
import tvicon from './assets/tvicon.svg';
import Calendar from './calendar';
import TimePicker from './timepicker';
import { useState } from 'react';

const Automatebillairtime = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate(-1); // This takes the user back to the previous page
    };
  
    const [biller, setBiller] = useState("");
    const [packageType, setPackageType] = useState("");
  
   // Package options for each biller
   const packageOptions = {
    GOTV: ["Max", "Jolli", "Jinja", "Smallie"],
    DSTV: ["Premium", "Compact Plus", "Compact", "Family", "Access", "Yanga", "Padi"],
    Startimes: ["Basic", "Smart", "Classic", "Super"],
  };
  
  // Form fields required for each biller
  const formFields = {
    DSTV: {
      idPlaceholder: "Enter smartcard number",
      idName: "Smartcard Number",
    },
    GOTV: {
      idPlaceholder: "Enter IUC number",
      idName: "IUC Number",
    },
    Startimes: {
      idPlaceholder: "Enter smartcard number",
      idName: "Smartcard Number",
    },
  
  
  
    }
    
  
    return (
      <div className="bg-[#E0ECF5] w-full p-4 md:p-[2rem] h-full">
        {/* Back Button */}
        <button
          onClick={handleBackClick}
          className="flex items-center w-[70px] h-[60px] md:w-[93px] md:h-[70px] bg-white rounded-full transition duration-200 mb-4 md:mb-6"
        >
          <img src={arrowicon} alt="Back" className="mx-auto" />
        </button>
  
        <div className="card-divs flex flex-col md:flex-row justify-center gap-8">
          {/* Form Section */}
          <div className="w-full max-w-[600px] bg-white p-6 md:p-[2rem] rounded-[20px]">
        <div className="flex justify-between items-center mb-6">
          <p className="font-bold text-[24px] md:text-[32px]">TV</p>
          <img src={tvicon} alt="TV Icon" />
        </div>
  
        {/* Biller Selection */}
        <select
          className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] bg-white text-black text-opacity-40 mb-6"
          onChange={(e) => setBiller(e.target.value)}
          value={biller}
        >
          <option value="" disabled>
            Select biller
          </option>
          <option value="GOTV">GOTV</option>
          <option value="DSTV">DSTV</option>
          <option value="Startimes">Startimes</option>
        </select>
  
        {biller && (
          <>
            {/* ID Number (Smartcard/IUC) */}
            <input
              type="text"
              className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
              placeholder={formFields[biller].idPlaceholder}
            />
  
            {/* Customer Name */}
            <input
              type="text"
              className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
              placeholder="Enter customer name"
            />
  
            {/* Phone Number */}
            <input
              type="text"
              className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
              placeholder="Enter phone number"
            />
  
            {/* Package Selection */}
            <select
              className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] bg-white text-black text-opacity-40 mb-6"
              onChange={(e) => setPackageType(e.target.value)}
              value={packageType}
            >
              <option value="" disabled>
                Select a package
              </option>
              {packageOptions[biller].map((pkg, index) => (
                <option key={index} value={pkg}>
                  {pkg}
                </option>
              ))}
            </select>
  
            {/* Amount to be Paid */}
            <input
              type="text"
              className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
              placeholder="Enter amount to be paid"
            />
  
            {/* Comment (Optional) */}
            <p className="font-semibold text-[18px] md:text-[24px] mb-2">Add a comment (Optional)</p>
            <textarea
              className="w-full h-[100px] md:h-[122px] border border-black rounded-[10px] p-3 md:p-4 resize-none mb-6"
              placeholder="Your comment here"
            ></textarea>
          </>
        )}
      </div>
  
          {/* Calendar and Time Picker Section */}
          <div className="w-full max-w-[600px] flex flex-col items-center bg-white p-6 md:p-[2rem] rounded-[20px]">
            <Calendar />
            <TimePicker />
  
            <button className="w-full max-w-[500px] h-[60px] md:h-[90px] bg-[#FF6600] text-white rounded-[20px] flex justify-center items-center text-[24px] md:text-[40px] font-medium mt-8">
              Create
            </button>
          </div>
        </div>
      </div>
      
    );
  };

export default Automatebillairtime
