import React from 'react'
import arrowicon from './assets/arrowicon.png';
import bankicon from './assets/bankicon.svg';
import { useNavigate } from 'react-router-dom';
const Banktransfer = () => {
    const navigate = useNavigate();
      
    const handleBackClick = () => {
      navigate(-1); // This takes the user back to the previous page
    };

  return (
   
<div className=' bg-[#E0ECF5] h-auto p-[2rem]'>
        <div className='w-full lg:w-[1057px] p-[1rem]  mx-auto'> 
      <button
        onClick={handleBackClick}
        className="flex items-center w-[70px] h-[60px] bg-white rounded-full transition duration-200 mb-4 md:mb-6"
      >
        <img src={arrowicon} alt="Back" className="mx-auto" />
      </button>
      </div>
<div className='bg-white   w-full lg:w-[1057px] mx-auto h-[757px] p-[1rem] md:p-[3rem] rounded-[20px]'>
  <h1 className='text-[36px]'> Bank Transfer</h1>
  <br />
  <img src={bankicon} alt="" />
  <br />
  <p className='w-full md:w-[558px] text-[16px]'>An official bank account has been created for you, just make a transfer to this account below and your account is automatically funded.</p>
 <br />

    <p className='w-full md:w-[558px] text-[20px]'><span className='font-bold'>Bank name:</span> Wema Bank</p>
    <p className='w-full md:w-[558px] text-[20px]'><span className='font-bold'>Account no:</span> 042135689</p>
    <p className='w-full md:w-[558px] text-[20px]'><span className='font-bold'>Account name:</span> AutoBillsPro - Clinton</p>
   </div>

    </div>
  )
}

export default Banktransfer
