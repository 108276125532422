import React from 'react'
import Sidenav from './sidenav'
import { Link } from 'react-router-dom'
import automatebillicon from './assets/automatebillicon.svg'
import payinstantlyicon from './assets/payinstantlyicon.svg'
import payinstantlywhiteicon from './assets/payinstantlywhiteicon.png'
import automatebillwhiteicon from './assets/automatebillwhiteicon.png'

const CreateBill = () => {
  return (
    <div className='md:flex md:bg-white bg-[#E0ECF5]'>
    <Sidenav/>
<div className='bg-[#E0ECF5]  w-full p-[1rem] md:p-[3rem] '>
  

<div className='w-[260px] h-[70] bg-white rounded-full text-center p-3'>
        <p className='text-[30px] font-bold'>Create a bill</p>
        </div>

<br />


<div className="flex flex-col lg:flex-row justify-center items-center gap-8 md:gap-12 mx-auto bg-white p-8 md:p-[10rem] rounded-[20px]">
  
  {/* Automate a Bill Card */}
  <Link to="/automate-bill" className="w-full md:w-auto">
    <div className="group border-2 border-[#FF6600] w-full md:w-[338px] h-auto  md:h-[301px] rounded-[20px] p-6 md:p-[3rem] text-[#FF6600] hover:text-white hover:bg-[#FF6600] duration-200 transition-all cursor-pointer flex flex-col items-center">
      {/* Default Image */}
      <img src={automatebillicon} alt="" className="mx-auto group-hover:hidden w-20 h-20 md:w-auto md:h-auto" />
      {/* Hover Image */}
      <img src={automatebillwhiteicon} alt="" className="mx-auto hidden group-hover:block transition-all duration-200 w-20 h-20 md:w-auto md:h-auto" />
      <h1 className="text-[20px] md:text-[30px] text-center mt-4">Automate a bill</h1>
    </div>
  </Link>

  {/* Pay Instantly Card */}
  <Link to="/pay-instantly" className="w-full md:w-auto">
    <div className="group border-2 border-[#FF6600] w-full md:w-[338px] h-auto  md:h-[301px] rounded-[20px] p-6 md:p-[3rem] text-[#FF6600] hover:text-white hover:bg-[#FF6600] duration-200 transition-all cursor-pointer flex flex-col items-center">
      {/* Default Image */}
      <img src={payinstantlyicon} alt="" className="mx-auto group-hover:hidden w-20 h-20 md:w-auto md:h-auto" />
      {/* Hover Image */}
      <img src={payinstantlywhiteicon} alt="" className="mx-auto hidden group-hover:block transition-all duration-200 w-20 h-20 md:w-auto md:h-auto" />
      <h1 className="text-[20px] md:text-[30px] text-center mt-4">Pay instantly</h1>
    </div>
  </Link>
</div>




</div>
  </div>
  )
}

export default CreateBill
