import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import slideshowLeft from './assets/slideshowright.png';
import slideshowRight from './assets/slideshowleft.png';
import profilepic1 from './assets/profilepic1.png';
import profilepic2 from './assets/profilepic2.png';
import profilepic3 from './assets/profilepic3.png';
import goldstar from './assets/goldstar.png';
import normalstar from './assets/normalstar.png';

const testimonials = [
  {
    name: 'John Solomon',
    location: 'Lagos State',
    review: 'This has to be the best bill payment app I have ever used. So easy to use and it isn’t complicated at all.',
    rating: 4,
    bgColor: 'bg-white',
    image: profilepic1,
  },
  {
    name: 'Uzo Osagie',
    location: 'Edo state',
    review: 'You don’t even have to log in all the time, once a bill has been automated you just have to be funding from time to time.',
    rating: 5,
    bgColor: 'bg-white',
    image: profilepic2,
  },
  {
    name: 'John Solomon',
    location: 'Lagos state',
    review: 'This has to be the best bill payment app I have ever used. So easy to use and it isn’t complicated at all.',
    rating: 4,
    bgColor: 'bg-white',
    image: profilepic3,
  },
  {
    name: 'John Solomon',
    location: 'Lagos State',
    review: 'This has to be the best bill payment app I have ever used. So easy to use and it isn’t complicated at all.',
    rating: 4,
    bgColor: 'bg-white',
    image: profilepic1,
  },
];

const Testimonials = () => {
  return (
    <div className="relative w-full">
      <br />
      <br /> 
      <Swiper
        modules={[Navigation]}
        spaceBetween={16}
        slidesPerView={1}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        breakpoints={{
          640: { slidesPerView: 1, spaceBetween: 16 },
          768: { slidesPerView: 2, spaceBetween: 20 },
          1024: { slidesPerView: 3, spaceBetween: 24 },
        }}
        className="testimonial-swiper"
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <div
              className={`lg:ml-[3rem] w-full max-w-[470px] sm:max-w-[350px] lg:max-w-[470px] h-auto md:h-[300px] ${testimonial.bgColor} rounded-[20px] shadow-xl`}
            >
              <div className="content p-4 sm:p-6 lg:p-[2rem]">
                <div className="flex items-center">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 sm:w-14 sm:h-14 lg:w-16 lg:h-16 rounded-full"
                  />
                  <div className="ml-4">
                    <p className="text-lg sm:text-xl lg:text-[30px] font-semibold">{testimonial.name}</p>
                    <p className="text-sm sm:text-base lg:text-[14px]">{testimonial.location}</p>
                  </div>
                </div>
                <p className="mt-4 sm:mt-6 lg:mt-[1rem] text-sm sm:text-base lg:text-[16px] w-full font-medium">
                  {testimonial.review}
                </p>
                <div className="flex gap-2 sm:gap-3 mt-6 sm:mt-8 lg:mt-[2rem] h-[20px] sm:h-[22px] lg:h-[25px]">
                  {[...Array(5)].map((_, i) => (
                    <img
                      key={i}
                      src={i < testimonial.rating ? goldstar : normalstar}
                      alt={i < testimonial.rating ? 'Gold Star' : 'Normal Star'}
                      className="w-4 h-4 sm:w-5 sm:h-5 lg:w-6 lg:h-6"
                    />
                  ))}
                  <p className="text-sm sm:text-base lg:text-[20px] font-bold">{testimonial.rating}.0</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom Navigation Buttons */}
      <div className="swiper-button-prev absolute left-4 md:left-[-50px] top-1/2 transform -translate-y-1/2 z-10">
        <img src={slideshowLeft} alt="Previous" />
      </div>
      <div className="swiper-button-next absolute right-4 md:right-[-50px] top-1/2 transform -translate-y-1/2 z-10">
        <img src={slideshowRight} alt="Next" />
      </div>
      
      <br />
        <br />
        <br />
    </div>
  );
};

export default Testimonials;
