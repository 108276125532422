import React from 'react'
import Mainnav from './mainnav'
import Footer from './footer'
const Termsofservices = () => {
  return (
    <div>
    <div  className="bg-[#FF6600] bg-opacity-10 h-full"> 
   <Mainnav />

   <div>
     <h1 className="w-full max-w-[811px] h-auto text-[40px] font-bold sm:text-[60px] lg:text-[90px] text-center mx-auto my-[5rem] text-[#FF6600]">
     Terms of <br />
     Service
     </h1>
     <br />
  

   </div>


   <div className="bg-[#FF6600] w-full text-white py-16 p-[2rem] lg:p-[4rem]">
  <div className='w-full lg:w-[954px]'>
    <p className='text-[24px] font-semibold'>Effective since 6th November, 2023</p>
    <br />
    <p className='text-[24px] font-semibold '>Welcome to AutoBillsPro. By using our services, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully before using our platform.</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>1. Acceptance of Terms</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>By accessing or using AutoBillsPro's services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree to these terms, you must not use our services.</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>2. Services Offered</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>We provide bill payment solutions to assist individuals and businesses in managing and paying their bills. These services are offered through our website, mobile app, or any other authorized channels.</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>3. User Responsibilities</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>-Ensure that all information provided during registration and payment is accurate and up-to-date.
-Maintain the security of your account credentials.
-Verify the accuracy of payment details (e.g., bill amount, recipient information) before confirming a transaction.</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>4. Fees and Charges</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>We may charge service fees for certain transactions or features. These fees will be disclosed before completing a payment. You are responsible for any applicable fees or charges associated with the use of our services.</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>5. Payment Authorization</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>By initiating a payment, you authorize us to process the payment on your behalf to the specified recipient. Payments cannot be canceled or refunded once processed unless explicitly permitted under applicable policies.</p>
  </div>
</div>
   

   <Footer />
 </div>
 </div>
  )
}

export default Termsofservices
