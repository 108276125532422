import React from 'react';
import { useNavigate } from 'react-router-dom';
import arrowicon from './assets/arrowicon.png';
import customicon from './assets/customicon.png';
import Calendar from './calendar';
import TimePicker from './timepicker';

const Automateabillcustom = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  return (
    <div className="bg-[#E0ECF5] w-full p-4 md:p-[2rem] h-full">
      {/* Back button */}
      <button
        onClick={handleBackClick}
        className="flex items-center w-[70px] h-[60px] bg-white rounded-full transition duration-200 mb-4 md:mb-6"
      >
        <img src={arrowicon} alt="Back" className="mx-auto" />
      </button>

      <div className="card-divs flex flex-col md:flex-row justify-center gap-8">
        {/* Form Section */}
        <div className="w-full max-w-[600px] bg-white p-6 md:p-[2rem] rounded-[20px]">
          <div className="flex justify-between items-center mb-6">
            <p className="font-bold text-[24px] md:text-[32px]">Customize a bill</p>
            <img src={customicon} alt="Custom Icon" />
          </div>

          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Title"
          />
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Amount(₦)"
          />

          <p className="font-semibold text-[18px] md:text-[24px] mb-2">Recipient details</p>
          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] mb-6"
            placeholder="Recipient account number"
          />
          <select className="border-b-2 border-black border-opacity-40 w-full p-3 md:p-[1rem] bg-white text-black text-opacity-40 mb-6">
            <option value="" disabled selected>
              Recipient bank
            </option>
            <option value="firstbank">First Bank</option>
            <option value="accessbank">Access Bank</option>
          </select>

          <p className="font-semibold text-[18px] md:text-[24px] mb-2">Add a comment(Optional)</p>
          <textarea
            className="w-full h-[100px] md:h-[122px] border border-black rounded-[10px] p-3 md:p-4 resize-none mb-6"
            placeholder="Your comment here"
          ></textarea>
        </div>

        {/* Calendar and Time Picker Section */}
        <div className="w-full max-w-[600px] flex flex-col items-center bg-white p-6 md:p-[2rem] rounded-[20px]">
          <Calendar />
          <TimePicker />

          <button className="w-full max-w-[500px] h-[60px] md:h-[90px] bg-[#FF6600] text-white rounded-[20px] flex justify-center items-center text-[24px] md:text-[40px] font-medium mt-8">
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default Automateabillcustom;
