import React from "react";
import { useNavigate } from "react-router-dom";
import arrowicon from "./assets/arrowicon.png";
import tvicon from "./assets/tvicon.svg";
import { useState } from "react";

const Payinstantlydata = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  const [biller, setBiller] = useState("");
  const [packageType, setPackageType] = useState("");

  // Package options for each biller
  const packageOptions = {
    GOTV: ["Max", "Jolli", "Jinja", "Smallie"],
    DSTV: [
      "Premium",
      "Compact Plus",
      "Compact",
      "Family",
      "Access",
      "Yanga",
      "Padi",
    ],
    Startimes: ["Basic", "Smart", "Classic", "Super"],
  };

  // Form fields required for each biller
  const formFields = {
    DSTV: {
      idPlaceholder: "Enter smartcard number",
      idName: "Smartcard Number",
    },
    GOTV: {
      idPlaceholder: "Enter IUC number",
      idName: "IUC Number",
    },
    Startimes: {
      idPlaceholder: "Enter smartcard number",
      idName: "Smartcard Number",
    },
  };

  return (
    <div className="bg-[#E0ECF5] w-full p-4 md:p-[2rem] h-[70rem] flex flex-col items-center">
      {/* Back Button */}
      <div className="w-full lg:w-[1057px]  mx-auto">
        <button
          onClick={handleBackClick}
          className="flex items-center w-16 h-16 md:w-[93px] md:h-[70px] bg-white rounded-full transition duration-200 mb-4"
        >
          <img src={arrowicon} alt="Back" className="mx-auto" />
        </button>
      </div>

      <div className="w-full max-w-[650px] bg-white p-4 md:p-[2rem] rounded-[20px] mt-4">
        {/* Header */}
        <div className="flex justify-between items-center">
          <p className="font-bold text-[24px] md:text-[32px]">TV</p>
          <img src={tvicon} alt="TV Icon" className="w-6 md:w-8" />
        </div>

        {/* Form Elements */}
        <div className="mx-auto bg-white p-6 md:p-[2rem] rounded-[20px]">
          {/* Biller Selection */}
          <select
            className="border-b-2 border-black border-opacity-40 w-full md:w-[516px] p-3 text-black text-opacity-60"
            onChange={(e) => setBiller(e.target.value)}
            value={biller}
          >
            <option value="" disabled>
              Select biller
            </option>
            <option value="GOTV">GOTV</option>
            <option value="DSTV">DSTV</option>
            <option value="Startimes">Startimes</option>
          </select>

          {/* Conditionally Rendered Form Fields */}
          {biller && (
            <div className="space-y-4 mt-4">
              {/* ID Number (Smartcard/IUC) */}
              <input
                type="text"
                className="border-b-2 border-black border-opacity-40 w-full md:w-[516px] p-3"
                placeholder={formFields[biller].idPlaceholder}
              />

              {/* Customer Name */}
              <input
                type="text"
                className="border-b-2 border-black border-opacity-40 w-full md:w-[516px] p-3"
                placeholder="Enter customer name"
              />

              {/* Phone Number */}
              <input
                type="text"
                className="border-b-2 border-black border-opacity-40 w-full md:w-[516px] p-3"
                placeholder="Enter phone number"
              />

              {/* Package Selection */}
              <select
                className="border-b-2 border-black border-opacity-40 w-full md:w-[516px] p-3 text-black text-opacity-60"
                onChange={(e) => setPackageType(e.target.value)}
                value={packageType}
              >
                <option value="" disabled>
                  Select a package
                </option>
                {packageOptions[biller].map((pkg, index) => (
                  <option key={index} value={pkg}>
                    {pkg}
                  </option>
                ))}
              </select>

              {/* Amount to be Paid */}
              <input
                type="text"
                className="border-b-2 border-black border-opacity-40 w-full md:w-[516px] p-3"
                placeholder="Enter amount to be paid"
              />

              {/* Comment (Optional) */}
              <div>
                <p className="font-semibold text-lg md:text-[24px]">
                  Add a comment
                </p>
                <br />
                <textarea
                  className="w-full md:w-[516px] h-[122px] border border-black rounded-[10px] p-4 resize-none"
                  placeholder="Your comment here"
                />
              </div>
            </div>
          )}
        </div>

        {/* Pay Button */}
        <button className="w-full md:w-[400px] h-16 md:h-[90px] bg-[#FF6600] text-white rounded-[20px] flex justify-center items-center text-2xl md:text-[40px] font-medium mt-6 mx-auto">
          Pay now
        </button>
      </div>
    </div>
  );
};
export default Payinstantlydata;
