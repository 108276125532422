import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import automatebillicon from "./assets/bankicon.svg";
import payinstantlyicon from "./assets/cardicon.svg";
import arrowicon from "./assets/arrowicon.png";
const Addmoney = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  return (
    <div className=" bg-[#E0ECF5]">
      <div className="w-full p-[1rem]  mx-auto">
        <button
          onClick={handleBackClick}
          className="flex items-center w-[70px] h-[60px] bg-white rounded-full transition duration-200 mb-4 md:mb-6"
        >
          <img src={arrowicon} alt="Back" className="mx-auto" />
        </button>
      </div>
      <div className="bg-white   w-full lg:w-[1057px] mx-auto h-[757px] p-[1rem] md:p-[3rem] rounded-[20px]">
        <br />
        <div className="m-auto bg-white p-4 md:p-[3rem] rounded-[20px] lg:h-[757px]">
          <h1 className="text-[36px]">Select a method</h1>
          <br />
          <div className="flex flex-col lg:flex-row justify-center items-center gap-8 md:gap-12 ">
            {/* Automate a Bill Card */}
            <Link to="/bank-transfer" className="w-full md:w-auto">
              <div className="group border-2 border-[#FF6600] w-full md:w-[338px] h-auto  md:h-[301px] rounded-[20px] p-6 md:p-[3rem] text-[#FF6600] duration-200 transition-all cursor-pointer flex flex-col items-center">
                {/* Default Image */}
                <img
                  src={automatebillicon}
                  alt=""
                  className="mx-auto  w-20 h-20 md:w-auto md:h-auto"
                />

                <h1 className="text-[20px] md:text-[30px] text-center mt-4">
                  Bank Transfer
                </h1>
              </div>
            </Link>

            {/* Pay Instantly Card */}
            <Link to="" className="w-full md:w-auto">
              <div className="group border-2 border-[#FF6600] w-full md:w-[338px] h-auto  md:h-[301px] rounded-[20px] p-6 md:p-[3rem] text-[#FF6600]  duration-200 transition-all cursor-pointer flex flex-col items-center">
                {/* Default Image */}
                <img
                  src={payinstantlyicon}
                  alt=""
                  className="mx-auto w-20 h-20 md:w-auto md:h-auto"
                />

                <h1 className="text-[20px] md:text-[30px] text-center mt-4">
                  Card
                </h1>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addmoney;
