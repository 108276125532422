import React from "react";
import Sidenav from "./sidenav";
import { Link } from "react-router-dom";
import { useState } from "react";
import plusicon from "./assets/plusicon.svg";
import whitewithorange from "./assets/AutoBillsPro Logo(white with orange).svg";
import blackandwhitelogo from "./assets/AutoBillsPro Icon(white with black) 1.png";
import cardqrcode from "./assets/Frame 74.png";
import wifiicon from "./assets/wifiicon.png";
import walleticon from "./assets/walleticon.svg";

const Card = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // const [isPopupOpen2, setIsPopupOpen2] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  // const togglePopup2 = () => {
  //   setIsPopupOpen2(!isPopupOpen2);
  // };
  return (
    <div className="md:flex md:bg-white bg-[#E0ECF5] ">
      <Sidenav />

      <div className="bg-[#E0ECF5] w-full min-h-screen p-[1rem] md:p-8">
        {/* Header Section */}
        <div className="md:flex justify-between items-center mb-6">
          <div className="w-full md:w-[260px] h-[70px] bg-white rounded-full text-center p-4 cursor-pointer">
            <p className="text-[24px] md:text-[30px] font-bold">Cards</p>
          </div>
        </div>

        {/* Info Section */}
        <div className="bg-white p-6 rounded-[20px] mb-6">
          <p className="text-[18px] md:text-[20px] font-bold text-center">
            This card can be used as a payment method on platforms that require
            a credit card, such as Netflix, Spotify, and similar services. It
            provides a convenient way to pay for subscriptions and other online
            transactions.
          </p>
        </div>

        {/* Main Content */}
        <div className="md:flex justify-between gap-6">
          {/* Left Section */}
          <div className="leftdiv w-full md:w-1/2 space-y-6">
            {/* Dollar Card */}
            <div className="w-full h-[231px] p-6 bg-white rounded-[20px] flex flex-col justify-between">
              <div className="flex justify-between">
                <span className="text-[20px] md:text-[24px] font-bold">
                  Dollar Card
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-[18px] md:text-[20px]">Balance</span>
                <span className="text-[24px] md:text-[30px] font-bold">
                  $230.00
                </span>
              </div>
            </div>

            {/* Add Money / Withdraw Buttons */}
            <div className="md:flex gap-4">
              <button onClick={togglePopup} className="w-full">
                <div className="flex justify-center items-center w-full h-[68px] bg-white rounded-[20px] mb-4 md:mb-0">
                  <img
                    src={plusicon}
                    alt="Add Money Icon"
                    className="w-[23px] h-[23px]"
                  />
                  <span className="text-[18px] md:text-[24px] text-[#FF6600] mx-2 font-bold">
                    Add Money
                  </span>
                </div>
              </button>
              <Link to="" className="w-full">
                <div className="flex justify-center items-center w-full h-[68px] bg-white rounded-[20px]">
                  <img
                    src={walleticon}
                    alt="Withdraw Icon"
                    className="w-[23px] h-[23px]"
                  />
                  <span className="text-[18px] md:text-[24px] text-[#FF6600] mx-2 font-bold">
                    Withdraw
                  </span>
                </div>
              </Link>
            </div>

            {/* Naira Card */}
            <div className="w-full h-[231px] p-6 bg-white rounded-[20px] flex flex-col justify-between">
              <div className="flex justify-between">
                <span className="text-[20px] md:text-[24px] font-bold">
                  Naira Card
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-[18px] md:text-[20px]">Balance</span>
                <span className="text-[24px] md:text-[30px] font-bold">
                  ₦50,830.00
                </span>
              </div>
            </div>
          </div>

          <br />
          {/* Right Section */}
          <div className="w-full md:w-1/2 space-y-6">
            {/* Dollar Card Details */}
            <div className="w-full h-[314px] p-6 text-white bg-black rounded-[20px]">
              <div className="flex justify-between">
                <p className="text-[20px] md:text-[24px] font-bold">
                  Dollar Card Details
                </p>
                <img src={wifiicon} alt="WiFi Icon" />
              </div>
              <img src={cardqrcode} alt="QR Code" className="mt-6" />
              <p className="text-[24px] md:text-[30px] font-bold">
                <div className="flex justify-between">
                  <span>7563</span> <span>6413</span> <span>0085</span>{" "}
                  <span>4374</span>
                </div>
              </p>
              <div className="mt-4 flex justify-between">
                <div className="flex space-x-10">
                  <div>
                    <p className="text-[12px] font-bold">MM/YY</p>
                    <p className="text-[18px] font-bold">11/30</p>
                  </div>
                  <div>
                    <p className="text-[12px] font-bold">MM/YY</p>
                    <p className="text-[18px] font-bold">11/30</p>
                  </div>
                </div>
                <img src={blackandwhitelogo} alt="Logo" className="h-[44px]" />
              </div>
            </div>

            {/* Naira Card Details */}
            <div className="w-full h-[314px] p-6 text-white bg-[#FF6600] rounded-[20px]">
              <div className="flex justify-between">
                <p className="text-[20px] md:text-[24px] font-bold">
                  Naira Card Details
                </p>
                <img src={wifiicon} alt="WiFi Icon" />
              </div>
              <img src={cardqrcode} alt="QR Code" className="mt-6" />
              <p className="text-[24px] md:text-[30px] font-bold">
                <div className="flex justify-between">
                  <span>7563</span> <span>6413</span> <span>0085</span>{" "}
                  <span>4374</span>
                </div>
              </p>
              <div className="mt-4 flex justify-between">
                <div className="flex space-x-10">
                  <div>
                    <p className="text-[12px] font-bold">MM/YY</p>
                    <p className="text-[18px] font-bold">11/30</p>
                  </div>
                  <div>
                    <p className="text-[12px] font-bold">MM/YY</p>
                    <p className="text-[18px] font-bold">11/30</p>
                  </div>
                </div>
                <img src={whitewithorange} alt="Logo" className="h-[44px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
