import React from 'react'
import Mainnav from './mainnav'
import Footer from './footer'
import questionmark from './assets/Group.png'
import img2 from './assets/Frame 25.png'
import img3 from './assets/Frame 26.png'
import img4 from './assets/Frame 30.png'
import img5 from './assets/Frame 31.png'
import img6 from './assets/Frame 32.png'
import phone from './assets/Frame 33.svg'
import mail from './assets/Frame 34.svg'
const Contactus = () => {
  return (
    <div>
       <div  className="bg-[#FF6600] bg-opacity-10 h-full"> 
      <Mainnav />

      <div>
        <h1 className="w-full max-w-[811px] h-auto text-[40px] font-bold sm:text-[60px] lg:text-[90px] text-center mx-auto text-[#FF6600]">
        Contact Us
        </h1>
        <br />
     
        <p className="w-full max-w-[711px] h-auto text-[16px] sm:text-[18px] lg:text-[20px] text-center mx-auto text-opacity-70">
        Get in touch with us to express your complaints, encounters on the platform,
questions and many more.
        </p>
      </div>


      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="bg-[#FF6600] w-full flex flex-wrap justify-evenly text-white py-16 px-1 gap-10">
      {/* 1 */}
     <div className=' w-[380px] text-center '>
        <img src={questionmark} alt="" className='mx-auto' />
        <h1 className='text-[36px] font-bold'>General Enquiries</h1>
        <p className='text-[20px] opacity-80'>For any enquiries about AutoBillsPro, send an email to:</p>
        <p className='text-[20px] underline font-bold'>enquiries@autobillsbro.com</p>
     </div>
      {/* 2 */}
     <div className=' w-[380px] text-center '>
        <img src={img2} alt="" className='mx-auto' />
        <h1 className='text-[36px] font-bold'>Media Enquiries</h1>
        <p className='text-[20px] opacity-80'>For any media enquiries or sponsor post send an email to:  </p>
        <p className='text-[20px] underline font-bold'>enquiries@autobillsbro.com</p>
     </div>
      {/* 3 */}
     <div className=' w-[380px] text-center '>
        <img src={img3} alt="" className='mx-auto' />
        <h1 className='text-[36px] font-bold'>Content</h1>
        <p className='text-[20px] opacity-80'>For any collaborations, a proposal can be sent to our email is:  </p>
        <p className='text-[20px] underline font-bold'>enquiries@autobillsbro.com</p>
     </div>
      {/* 4 */}
     <div className=' w-[380px] text-center '>
        <img src={img4} alt="" className='mx-auto' />
        <h1 className='text-[36px] font-bold'>Whatsapp</h1>
        <p className='text-[20px] opacity-80'>We are also available on whatsapp: </p>
        <p className='text-[20px]  font-bold'>+234806547839</p>
     </div>
      {/* 5 */}
     <div className=' w-[380px] text-center '>
        <img src={img5} alt="" className='mx-auto' />
        <h1 className='text-[36px] font-bold'>Telegram</h1>
        <p className='text-[20px] opacity-80'>We have a telegram channel for our users interact and get updates, below is the link to the channel:</p>
        <p className='text-[20px]  font-bold'>t.me/autobillspro</p>
     </div>
      {/* 6 */}
     <div className=' w-[380px] text-center '>
        <img src={img6} alt="" className='mx-auto' />
        <h1 className='text-[36px] font-bold'>Physical Address</h1>
        <p className='text-[20px]  font-bold'>No. 8, Tayo street, Off Third Mainlad brigde, Ikoyi, Nigeria.</p>
     </div>




      </div>

<div className='md:flex justify-evenly mx-[2rem] py-[7rem]'>
<div className='flex gap-5'>
<img src={phone} alt="" className='h-[79px] my-auto'/>
<div>
    <h1 className='text-[50px] text-[#FF6600]'>Quick Contact</h1>
    <p className='text-[26px]'>+2347094658383</p>
</div>
</div>
<div className='flex gap-5'>
<img src={mail} alt="" className=' h-[79px] my-auto'/>
<div>
    <h1 className='text-[50px] text-[#FF6600]'>Mail</h1>
    <p className='text-[26px]'>+enquiries@autobillspro.com</p>
</div>
</div>
</div>
      

      <Footer />
    </div>
    </div>
  )
}

export default Contactus

