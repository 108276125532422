import React from 'react';
import blackandwhitelogo from './assets/blackandwhitelogo.png';
import linkedinlogo from './assets/linkedinlogo.png';
import iglogo from './assets/iglogo.png';
import xlogo from './assets/xlogo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="flex flex-wrap justify-between gap-10 py-[6rem] p-[2rem] lg:px-[4rem] w-full bg-black text-white">
      {/* Logo and Social Media */}
      <div className="flex flex-col items-start space-y-3 w-full md:w-auto">
        <img src={blackandwhitelogo} alt="Logo" />
        <span></span>
        <div className="flex justify-evenly gap-4 w-[10rem]">
          <img src={linkedinlogo} alt="LinkedIn" className="w-6 h-6" />
          <img src={iglogo} alt="Instagram" className="w-6 h-6" />
          <img src={xlogo} alt="X (Twitter)" className="w-6 h-6" />
        </div>
      </div>

      {/* Services */}
      <div className="w-full md:w-auto space-y-5">
       <Link to=""> <p className="font-semibold text-[28px]">Services</p> </Link>
        <Link to="/services-Automate-bill"><p className="font-semibold text-[24px]   hover:text-[#FF6600] duration-150 opacity-60">Automate a bill</p>     </Link>
        <Link to="/services-Payinstantly"><p className="font-semibold text-[24px]   hover:text-[#FF6600] duration-150 opacity-60">Pay Instantly</p>     </Link>
        <Link to="/services-Upcoming-bills"><p className="font-semibold text-[24px]   hover:text-[#FF6600] duration-150 opacity-60">See upcoming bills</p>     </Link>
        <Link to="/services-Bill-payments-history"><p className="font-semibold text-[24px]   hover:text-[#FF6600] duration-150 opacity-60">Bill payment history</p>     </Link>
      </div>

      {/* Company */}
      <div className="w-full md:w-auto space-y-5">
        <Link to=""><p className="font-semibold text-[28px]">Company</p>     </Link>
        <Link to="/about"><p className="font-semibold text-[24px]   hover:text-[#FF6600] duration-150 opacity-60">About</p>     </Link>
        <Link to="/how-it-works"><p className="font-semibold text-[24px]   hover:text-[#FF6600] duration-150 opacity-60">How it works</p>     </Link>
        <Link to="/contact-Us"><p className="font-semibold text-[24px]   hover:text-[#FF6600] duration-150 opacity-60">Contact Us</p>     </Link>
        <Link to="/Frequently-asked-questions"><p className="font-semibold text-[24px]   hover:text-[#FF6600] duration-150 opacity-60">FAQs</p>     </Link>
      </div>

      {/* Legal */}
      <div className="w-full md:w-auto space-y-5">
       <Link to=""> <p className="font-semibold text-[28px]">Legal</p>     </Link>
       <Link to="/termsofservices"> <p className="font-semibold text-[24px]   hover:text-[#FF6600] duration-150 opacity-60">Terms of service</p>     </Link>
       <Link to="/privacypolicy"> <p className="font-semibold text-[24px]   hover:text-[#FF6600] duration-150 opacity-60">Privacy policy</p>     </Link>
      </div>
    </div>
  );
};

export default Footer;
