import React, { useState } from 'react';
import leftarrow from './assets/leftarrow.png';
import rightarrow from './assets/rightarrow.png';

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  // Helper functions
  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
  const getFirstDayOfMonth = (month, year) => new Date(year, month, 1).getDay();

  // Change month when left or right arrow is clicked
  const changeMonth = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(currentDate.getMonth() + direction);
    setCurrentDate(newDate);
  };

  const renderCalendarDates = () => {
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const firstDay = getFirstDayOfMonth(month, year);
    const daysInCurrentMonth = daysInMonth(month, year);

    const dates = [];
    for (let i = 0; i < firstDay; i++) {
      dates.push(<div key={`empty-${i}`} className="h-10" />); // Empty slots before the first day
    }
    for (let day = 1; day <= daysInCurrentMonth; day++) {
      dates.push(
        <div
          key={day}
          className="h-10 flex font-bold items-center justify-center cursor-pointer hover:bg-gray-200"
        >
          {day}
        </div>
      );
    }
    return dates;
  };

  return (
    <div className="w-full max-w-[541px] bg-white rounded-[20px] p-4 md:p-[2rem]">
      <p className="font-bold text-[20px] md:text-[30px] text-center md:text-left">Set date and time</p>

      {/* Month and Year Navigation */}
      <div className="flex items-center justify-between mt-4">
        <button onClick={() => changeMonth(-1)} className="text-2xl font-bold">
          <img src={leftarrow} alt="Previous Month" className="w-[18px] h-[19px] md:w-[20.25px] md:h-[21px]" />
        </button>
        <span className="text-lg md:text-xl font-semibold">
          {currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}
        </span>
        <button onClick={() => changeMonth(1)} className="text-2xl font-bold">
          <img src={rightarrow} alt="Next Month" className="w-[18px] h-[19px] md:w-[20.25px] md:h-[21px]" />
        </button>
      </div>

      {/* Calendar Grid */}
      <div className="grid grid-cols-7 gap-1 md:gap-2 mt-4 text-center text-sm md:text-base">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="font-semibold">
            {day}
          </div>
        ))}
        {renderCalendarDates()}
      </div>
    </div>
  );
};

export default Calendar;
