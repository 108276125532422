import React from "react";
import { useNavigate } from "react-router-dom";
import arrowicon from "./assets/arrowicon.png";
import customicon from "./assets/customicon.png";

const Payinstantlycustom = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // This takes the user back to the previous page
  };

  return (
    <div className="bg-[#E0ECF5] w-full p-4 md:p-[2rem] h-[60rem] flex flex-col items-center">
      {/* Back Button */}
      <div className="w-full lg:w-[1057px]  mx-auto">
        <button
          onClick={handleBackClick}
          className="flex items-center w-16 h-16 md:w-[93px] md:h-[70px] bg-white rounded-full transition duration-200"
        >
          <img src={arrowicon} alt="" className="mx-auto" />
        </button>
      </div>

      <div className="w-full max-w-[1057px] bg-white p-4 md:p-[2rem] rounded-[20px] mt-4">
        {/* Header */}
        <div className="flex justify-between items-center">
          <p className="font-bold text-[24px] md:text-[32px]">
            Customize a bill
          </p>
          <img src={customicon} alt="" className="w-6 md:w-8" />
        </div>

        {/* Form Elements */}
        <div className="space-y-4 mt-4">
          <select className="border-b-2 border-black border-opacity-40 w-full md:w-[516px] p-3 text-black text-opacity-60">
            <option value="" disabled selected>
              Select biller
            </option>
            <option value="electricity">Electricity</option>
            <option value="water">Water</option>
            <option value="internet">Internet</option>
            {/* Add more options as needed */}
          </select>

          <input
            type="text"
            className="border-b-2 border-black border-opacity-40 w-full md:w-[516px] p-3"
            placeholder="Enter smartcard number"
          />

          <select className="border-b-2 border-black border-opacity-40 w-full md:w-[516px] p-3 text-black text-opacity-60">
            <option value="" disabled selected>
              Select a plan
            </option>
            <option value="basic">Basic Plan</option>
            <option value="standard">Standard Plan</option>
            <option value="premium">Premium Plan</option>
            <option value="pro">Pro Plan</option>
            {/* Add more plan options if needed */}
          </select>

          <div>
            <p className="font-semibold text-lg md:text-[24px]">
              Add a comment
            </p>
            <br />
            <textarea className="w-full md:w-[516px] h-[122px] border border-black rounded-[10px] p-4 resize-none" />
          </div>
        </div>

        {/* Pay Button */}
        <button className="w-full md:w-[400px] h-16 md:h-[90px] bg-[#FF6600] text-white rounded-[20px] flex justify-center items-center text-2xl md:text-[40px] font-medium mt-6 mx-auto">
          Pay now
        </button>
      </div>
    </div>
  );
};

export default Payinstantlycustom;
