import React from 'react'
import Mainnav from './mainnav'
import Footer from './footer'


const About = () => {
  return (
    <div>
    <div  className="bg-[#FF6600] bg-opacity-10 h-full"> 
   <Mainnav />

   <div>
     <h1 className="w-full max-w-[811px] h-auto text-[40px] font-bold sm:text-[60px] lg:text-[90px] text-center mx-auto text-[#FF6600]">
     About
     </h1>
     <br />
  
     <p className="w-full max-w-[711px] h-auto text-[16px] sm:text-[18px] lg:text-[20px] text-center mx-auto text-opacity-70">
     A little about AutoBillsPro
     </p>
   </div>


   <br />
   <br />
   <br />
   <br />
   <br />

   <div className="bg-[#FF6600] w-full text-white py-16 p-[2rem] lg:p-[4rem]">
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[60px]'>Objective</h1>
    <p className='text-[28px] font-semibold opacity-80'>To provide reliable, efficient, and secure bill payment solutions that simplify financial management, ensuring timely payments, transparency, and exceptional customer service for individuals and businesses.</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[60px]'>Mission</h1>
    <p className='text-[28px] font-semibold opacity-80'>Our mission is to streamline the bill payment process by offering a secure, user-friendly platform that empowers individuals and businesses to manage their finances with ease, accuracy, and confidence. We are committed to delivering exceptional service, promoting financial well-being, and fostering trust through innovation and reliability.</p>
  </div>
</div>
   

   <Footer />
 </div>
 </div>
  )
}

export default About



