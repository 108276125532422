import React from 'react'
import Sidenav from './sidenav'
import profilepicture from './assets/profilepicture.png'

const Profile = () => {
  return (
    
    <div className='md:flex md:bg-white bg-[#E0ECF5]'>
    <Sidenav/>

    <div className='bg-[#E0ECF5]   w-full p-[2rem] h-full'>

<div className='mx-auto w-auto'>
       
   <div className='flex justify-center items-center  w-[227px]  h-[70px] bg-white rounded-full'>
     <p className="text-center font-bold  text-[30px] ">Profile</p>  
    </div>
<br />
</div>

<div className="m-auto w-full max-w-[1190px] h-auto bg-white p-4 md:p-[2rem] rounded-[20px]">
  <br />
  <div>
    <p className="font-semibold text-[18px] opacity-50 mb-5">Profile picture</p>
    <img src={profilepicture} alt="Profile" className="w-24 h-24 md:w-32 md:h-32 rounded-full mx-auto md:mx-0" />
    <br /><br />

    <div className="flex flex-col md:flex-row md:gap-24 gap-8">
      {/* Profile Info Column 1 */}
      <div className="flex-1">
        {/* Name */}
        <p className="font-semibold text-[16px] md:text-[18px] opacity-50">First Name</p>
        <p className="font-bold text-[20px] md:text-[22px]">Clinton</p>
        <br />
        {/* Name */}
        <p className="font-semibold text-[16px] md:text-[18px] opacity-50">Last Name</p>
        <p className="font-bold text-[20px] md:text-[22px]">Clinton</p>
        <br />

        {/* Email */}
        <p className="font-semibold text-[16px] md:text-[18px] opacity-50">Email</p>
        <p className="font-bold text-[20px] md:text-[22px]">Clintonjames<br className='block sm:hidden' />@gmail.com</p>
        <br />

    
        
        {/* State */}
        <p className="font-semibold text-[16px] md:text-[18px] opacity-50">State</p>
        <p className="font-bold text-[20px] md:text-[22px]">Imo</p>
        <br />
        <br />


        <button className="border-black border p-2 text-[16px] md:text-[18px] w-full md:w-[103px] h-[42px] rounded-[10px] mt-4 md:mt-0">
          Save
        </button>
      </div>

      {/* Profile Info Column 2 */}
      <div className="flex-1">
        {/* Phone Number */}
        <p className="font-semibold text-[16px] md:text-[18px] opacity-50">Phone number</p>
        <p className="font-bold text-[20px] md:text-[22px]">+2341234567890</p>
        <br /><br />
        {/* country */}
        <p className="font-semibold text-[16px] md:text-[18px] opacity-50">Country</p>
        <p className="font-bold text-[20px] md:text-[22px]">Nigeria</p>
        <br /><br />

        

      </div>
    </div>
  </div>
</div>


<br />

</div>
</div>
)
}

export default Profile
