import React from 'react';
import { Link } from 'react-router-dom';
import signupImg from './assets/unsplash_L8tWZT4CcVQ.png';
import logo1 from './assets/AutoBillsPro Logo(Original) 1.png';

const Login = () => {
  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Left Side Image */}
      <div
        className="hidden lg:block w-full lg:w-1/2 bg-cover bg-center"
        style={{ backgroundImage: `url(${signupImg})` }}
      >
       <h1 className="text-white text-center text-[5rem] font-bold mt-[69vh] px-6">
          Paying Bills Just Got Easier
        </h1>
      </div>

      {/* Right Side Form */}
      <div className="flex flex-col justify-center items-center w-full lg:w-1/2 p-6 lg:p-12">
        <img src={logo1} alt="Logo" className="mx-auto mb-6" />

        {/* Toggle between Email and Phone Login */}
        <div className="flex mt-5 mb-6 w-full max-w-md">
          <Link
            to="/login"
            className="bg-[#FF6600] px-8 py-2 text-center rounded-s-full text-white font-bold w-1/2"
          >
            Email
          </Link>
          <Link
            to="/phone-login"
            className="bg-[#E8E8E8] px-8 py-2 text-center rounded-e-full text-black opacity-50 font-bold w-1/2"
          >
            Phone
          </Link>
        </div>

        {/* Input Fields */}
        <div className="w-full max-w-md">
          <input
            type="email"
            placeholder="Email address"
            className="block border-b-2 border-black w-full px-2 py-2 mt-4"
          />
          <input
            type="password"
            placeholder="Password"
            className="block border-b-2 border-black w-full px-2 py-2 mt-4"
          />
        </div>

        {/* Forgot Password Link */}
        <p className="mt-3 text-center w-full max-w-md">
          <Link to="#" className="text-[#FF6600]">
            Forgot password?
          </Link>
        </p>

        {/* Login Button */}
        <Link to="/dashboard" className="w-full max-w-md mt-6">
          <button className="bg-[#FF6600] w-full h-[62px] rounded-full text-white font-bold">
            Log In
          </button>
        </Link>

        {/* Sign Up Link */}
        <p className="font-medium text-center mt-3">
          Don't have an account?{' '}
          <Link to="/" className="text-[#FF6600]">
            Sign up
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
