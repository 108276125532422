import React from 'react'

import arrowicon from './assets/arrowicon.png';
import bankicon from './assets/walleticon.svg';
import { useNavigate } from 'react-router-dom';
const Withdraw = () => {
    const navigate = useNavigate();
      
    const handleBackClick = () => {
      navigate(-1); // This takes the user back to the previous page
    };
  return (
   
    <div className=' bg-[#E0ECF5] h-auto p-[2rem]'>
    <div className='w-full lg:w-[1057px] p-[1rem]  mx-auto'> 
  <button
    onClick={handleBackClick}
    className="flex items-center w-[70px] h-[60px] bg-white rounded-full transition duration-200 mb-4 md:mb-6"
  >
    <img src={arrowicon} alt="Back" className="mx-auto" />
  </button>
  </div>
<div className='bg-white   w-full lg:w-[1057px] mx-auto h-[757px] p-[1rem] md:p-[3rem] rounded-[20px]'>
<h1 className='text-[36px]'> Withdrawal</h1>
<br />
<img src={bankicon} alt="" className='h-[83px] w-[93px]'/>
<br />
<p className='w-full md:w-[558px] text-[16px]'>You will be credited in the next 24 hours</p>
<br />


      {/* Bank Selector */}
      <div>
       
        <input
          type="text"
          id="account-number"
          placeholder="Enter account number"
          className="w-full p-3 border-b border-black  md:w-[516px] mt-1 focus:outline-none "
        />
      </div>

      {/* Bank Selector */}
      <div>
      <br />
        <select
          id="bank"
          className="w-full p-3 border-b border-black  md:w-[516px] mt-1  "
          defaultValue=""
        >
          
          <option value="" disabled>Select bank</option>
          <option value="access">Access Bank</option>
          <option value="citibank">Citibank Nigeria</option>
          <option value="ecobank">Ecobank Nigeria</option>
          <option value="fidelity">Fidelity Bank</option>
          <option value="first-bank">First Bank of Nigeria</option>
          <option value="fcmb">First City Monument Bank (FCMB)</option>
          <option value="globus">Globus Bank</option>
          <option value="gtb">Guaranty Trust Bank (GTBank)</option>
          <option value="heritage">Heritage Bank</option>
          <option value="keystone">Keystone Bank</option>
          <option value="polaris">Polaris Bank</option>
          <option value="providus">Providus Bank</option>
          <option value="stanbic">Stanbic IBTC Bank</option>
          <option value="standard-chartered">Standard Chartered Bank</option>
          <option value="sterling">Sterling Bank</option>
          <option value="suntrust">SunTrust Bank</option>
          <option value="union">Union Bank of Nigeria</option>
          <option value="uba">United Bank for Africa (UBA)</option>
          <option value="unity">Unity Bank</option>
          <option value="wema">Wema Bank</option>
          <option value="zenith">Zenith Bank</option>
        </select>
      </div>
<br />
      {/* Amount Input */}
      <div>
       
        <input
          type="number"
          id="amount"
          placeholder="Enter amount"
          className="w-full p-3 border-b border-black  md:w-[516px] mt-1 focus:outline-none focus:ring-2 "
        />
      </div>

<div className='flex justify-end items-center md:mt-[4rem]'>
<button className="w-full md:w-[347.28px] h-[68px]  bg-[#FF6600] text-white rounded-[20px]  text-[24px] md:text-[40px] font-medium mt-8">
      Withdraw
          </button>
    
</div>
   
    </div>
</div>


  )
}

export default Withdraw
