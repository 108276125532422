import React from 'react'
import Mainnav from './mainnav'
import Footer from './footer'
import howtogrtstarted from './assets/macbook-pro-template-being-used-next-to-a-water-bottle-a19519 1.png'
import dashboardimg from './assets/b371dbf5-edab-40d6-9419-8ed290b46d73 1.png'
import walletandcardimg from './assets/8b37af61-0ff0-4643-ab94-f6c3961fe9c0 1.png'
import upcommingbillsimg from './assets/107241bb-617b-4fa1-a385-7d267737d7f2 1.png'
import payingbillsimg from './assets/9cc02e04-05a0-402e-921c-77b5d8e1b755 1.png'
const Howitworks = () => {
  return (
    <div  className="bg-[#FF6600] bg-opacity-10 h-full"> 
      <Mainnav />

      <div>
        <h1 className="w-full max-w-[811px] h-auto text-[40px] font-bold sm:text-[60px] lg:text-[90px] text-center mx-auto text-[#FF6600]">
        How AutoBillsPro works
        </h1>
        <br />
     
        <p className="w-full max-w-[711px] h-auto text-[16px] sm:text-[18px] lg:text-[20px] text-center mx-auto text-opacity-70">
        Do not have an idea on what we offer. Let us show you
        how AutoBillsPro works.
        </p>
      </div>


      <br />
      <br />
      <br />
      <br />

      <div className="bg-[#FF6600] w-full text-white py-4 lg:py-16 px-2 gap-10">
        {/* 1 */}
        <div className='lg:flex justify-evenly m-auto p-10 space-y-10 lg:space-y-0'>
             <div className='w-full md:w-[440px] mx-auto lg:mx-0'>
                <h1 className='text-[70px] font-bold' >1</h1>
                <h1 className='text-[40px] font-' >Sign up/Log in</h1>
                <p className='text-[20px]'>Creating an account or even logging in is super easy as it only requires either your phone number or email to sign in or log in.</p>
             </div>

             <img src={howtogrtstarted} alt="" className="w-full mx-auto lg:mx-0 md:w-[440px]"/>
        </div>

        {/* 2 */}
        <div className='lg:flex justify-evenly mx-auto lg:mx-0 p-10 space-y-10 lg:space-y-0'>
            <img src={dashboardimg} alt="" className="hidden lg:block  w-full md:w-[440px]"/>
             <div className='w-full md:w-[440px]    mx-auto lg:mx-0'>
                <h1 className='text-[70px] font-bold' >2</h1>
                <h1 className='text-[40px] font-' >Dashboard</h1>
                <p className='text-[20px]'>Dashboard is were you see an overview of your transactions, stats, wallet status and your wallet. From the dashboard there is even an option to fund your wallet or even withdraw the money in your wallet.</p>
             </div>
             <img src={dashboardimg} alt="" className="mx-auto block lg:hidden w-full md:w-[440px]"/>
        </div>

        {/* 3 */}
        <div className='lg:flex justify-evenly m-auto p-10 space-y-10 lg:space-y-0'>
             <div className='w-full md:w-[440px]  mx-auto lg:mx-0'>
                <h1 className='text-[70px] font-bold' >3</h1>
                <h1 className='text-[40px] font-' >Wallet and Cards</h1>
                <p className='text-[20px]'>AutoBillsPro provides you with an official dollar and naira card to pay for bills that requires credit card which can be funded from money in your wallet. Your wallet is easy fundable by either card or bank transfer. </p>
             </div>

             <img src={walletandcardimg} alt="" className=" mx-auto lg:mx-0 w-full md:w-[440px]"/>
        </div>

        {/* 4 */}
        <div className='lg:flex justify-evenly m-auto p-10 space-y-10 lg:space-y-0'>
        <img src={upcommingbillsimg} alt="" className="hidden lg:block  w-full md:w-[440px]"/>
             <div className='w-full md:w-[440px]  mx-auto lg:mx-0'>
                <h1 className='text-[70px] font-bold' >4</h1>
                <h1 className='text-[40px] font-' >Upcoming Bills</h1>
                <p className='text-[20px]'>It is quite easy to track your history and upcoming bills. You can retry, edit and cancel bills at your convenience.</p>
             </div>
             <img src={upcommingbillsimg} alt="" className="mx-auto block lg:hidden  w-full md:w-[440px]"/>
        </div>

     {/* 5 */}
     <div className='lg:flex justify-evenly m-auto p-10 space-y-10 lg:space-y-0'>
             <div className='w-full md:w-[440px]  mx-auto lg:mx-0'>
                <h1 className='text-[70px] font-bold' >5</h1>
                <h1 className='text-[40px] font-' >Paying Bills</h1>
                <p className='text-[20px]'>With AutoBillsPro all you have to do is automate a bill which is to set a date and time in which a bill will be paid and also renewed.</p>
             </div>

             <img src={payingbillsimg} alt="" className=" mx-auto lg:mx-0 w-full md:w-[440px]"/>
        </div>


      </div>

      <Footer />
    </div>
  )
}

export default Howitworks
