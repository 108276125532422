import React from 'react'
import Mainnav from './mainnav'
import Footer from './footer'

const Privacypolicy = () => {
  return (
    <div>
    <div  className="bg-[#FF6600] bg-opacity-10 h-full"> 
   <Mainnav />

   <div>
     <h1 className="w-full max-w-[811px] h-auto text-[40px] font-bold sm:text-[60px] lg:text-[90px] text-center mx-auto my-[8rem] text-[#FF6600]">
     Privacy Policy
     </h1>
     <br />
  

   </div>


   <div className="bg-[#FF6600] w-full text-white py-16 p-[2rem] lg:p-[4rem]">
  <div className='w-full lg:w-[954px]'>
    <p className='text-[24px] font-semibold'>Effective since 6th November, 2023</p>
    <br />
    <p className='text-[24px] font-semibold '>AutoBillsPro is committed to protecting the privacy of our users. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our bill payment services. By using our services, you agree to the terms outlined in this Privacy Policy.</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>1. Information We Collect</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>We may collect the following types of information:
Personal Information: Name, email address, phone number, billing address, and payment information. <br />
Account Information: Login credentials, account activity, and transaction history. <br />
Technical Information: IP address, browser type, device information, and usage data. <br />
Other Information: Any additional details you provide while using our services (e.g., customer support inquiries).</p>
  </div>

  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>2. How We Use Your Information</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>We use the information collected for the following purposes: <br />
To process and complete your bill payments. <br />
To verify your identity and ensure security. <br />
To provide customer support and address your inquiries.<br />
To improve our services, platform performance, and user experience. <br />
To comply with legal obligations and prevent fraud or unauthorized activities.</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>3. Sharing Your Information</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>We do not sell or rent your personal information. However, we may share your information with: <br />
Service Providers: Third parties that assist in processing payments or providing other operational services. <br />
Regulatory Authorities: To comply with legal obligations or respond to lawful requests. <br />
Business Partners: With your consent, for additional services or features.</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>4. Data Security</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>We use industry-standard encryption and security measures to protect your personal information. While we strive to safeguard your data, no transmission or storage system is 100% secure.</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>5. Your Rights</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>Depending on your jurisdiction, you may have the following rights regarding your personal information: <br />
Access, correct, or update your personal data. <br />
Request the deletion of your information, subject to legal requirements.<br />
Restrict or object to the processing of your information.<br />
Withdraw consent for specific uses of your data (e.g., marketing)..</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>6. Cookies and Tracking Technologies</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>We may use cookies or similar technologies to enhance your experience, analyze usage, and personalize our services. You can manage your cookie preferences through your browser settings.</p>
  </div>
  <br />
  <br />
  <div className='w-full lg:w-[954px]'>
    <h1 className='text-[38px]'>7. Third-Party Links</h1>
    <br />
    <p className='text-[24px] opacity-80 font-semibold'>Our platform may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties.</p>
  </div>
</div>
   

   <Footer />
 </div>
 </div>
  )
}

export default Privacypolicy

