import React, { useState } from "react";
import { Link } from "react-router-dom";
import signupImg from "./assets/unsplash_L8tWZT4CcVQ.png";
import logo1 from "./assets/AutoBillsPro Logo(Original) 1.png";

const PhoneSignup = () => {
  const [selectedOptions, setSelectedOptions] = useState({
    agreement: false,
    agreement2: false,
  });

  const handleCheckboxChange = (key) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  // const [selectedOption, setSelectedOption] = useState(null);
  const [country, setCountry] = useState("Nigeria"); // default to Nigeria
  const [state, setState] = useState("");

  // const handleRadioClick = (value) => {
  //   if (selectedOption === value) {
  //     setSelectedOption(null);
  //   } else {
  //     setSelectedOption(value);
  //   }
  // };

  // List of Nigerian states
  const nigerianStates = [
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
  ];

  return (
    <div className="flex flex-col lg:flex-row min-h-screen">
      {/* Left Side Image */}
      <div
        className="hidden lg:block w-full lg:w-1/2 bg-cover bg-center"
        style={{ backgroundImage: `url(${signupImg})` }}
      >
        <h1 className="text-white text-center text-[5rem] font-bold mt-[70vh] px-6">
          Paying Bills Just Got Easier
        </h1>
      </div>

      {/* Right Side Form */}
      <div className="flex flex-col justify-center items-center w-full lg:w-1/2 p-6 lg:p-12">
        <img src={logo1} alt="Logo" className="mx-auto mb-6" />

        {/* Toggle between Email and Phone Signup */}
        <div className="flex mt-5 mb-6 w-full max-w-md">
          <Link
            to="/"
            className="bg-[#E8E8E8] px-8 py-2 text-center rounded-s-full text-black opacity-50 font-bold w-1/2"
          >
            Email
          </Link>
          <Link
            to="/phone-signup"
            className="bg-[#FF6600] px-8 py-2 text-center rounded-e-full text-white font-bold w-1/2"
          >
            Phone
          </Link>
        </div>

        {/* Input Fields */}
        <div className="w-full max-w-md">
          <input
            type="text"
            placeholder="First Name"
            className="block border-b-2 border-black w-full px-2 py-2 mt-4"
          />
          <input
            type="text"
            placeholder="Last Name"
            className="block border-b-2 border-black w-full px-2 py-2 mt-4"
          />
          <input
            type="text"
            placeholder="Phone number"
            className="block border-b-2 border-black w-full px-2 py-2 mt-4"
          />
          <input
            type="password"
            placeholder="Password"
            className="block border-b-2 border-black w-full px-2 py-2 mt-4"
          />

          {/* Country Dropdown */}
          <select
            className="block border-b-2 border-black w-full px-2 py-2 mt-4"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          >
            <option value="Nigeria">Nigeria</option>
          </select>

          {/* State Dropdown */}
          <select
            className="block border-b-2 border-black w-full px-2 py-2 mt-4"
            value={state}
            onChange={(e) => setState(e.target.value)}
          >
            <option value="">Select State</option>
            {nigerianStates.map((stateName, index) => (
              <option key={index} value={stateName}>
                {stateName}
              </option>
            ))}
          </select>
        </div>

        {/* Terms and Conditions */}
        <div className="mt-5 font-medium text-center w-full max-w-md">
          <label className="flex items-center justify-center space-x-2">
            <input
              type="checkbox"
              checked={selectedOptions.agreement}
              onChange={() => handleCheckboxChange("agreement")}
              className="form-checkbox"
            />
            <span>
              I agree to the{" "}
              <Link to="/termsofservices">
                <span className="text-[#FF6600]">terms of Services</span>
              </Link>{" "}
              and{" "}
              <Link to="/privacypolicy">
                <span className="text-[#FF6600]">privacy policy</span>
              </Link>
            </span>
          </label>
        </div>

        <br />

        {/* Sign Up Button */}
        <Link to="/dashboard" className="w-full max-w-md mt-6">
          <button className="bg-[#FF6600] w-full h-[62px] rounded-full text-white font-bold">
            Sign Up
          </button>
        </Link>

        {/* Log in Link */}
        <p className="font-medium text-center mt-3 w-full max-w-md">
          Already have an Account?{" "}
          <Link to="/phone-login" className="text-[#FF6600]">
            Log in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default PhoneSignup;
