import React, { useState } from "react";
import leftarrow from "./assets/leftarrow.png";

const TimePicker = () => {
  const [hour, setHour] = useState("12");
  const [minute, setMinute] = useState("00");
  const [period, setPeriod] = useState("PM");

  // Toggle between AM and PM
  const togglePeriod = () => {
    setPeriod((prevPeriod) => (prevPeriod === "AM" ? "PM" : "AM"));
  };

  // Hour and minute options
  const hours = Array.from({ length: 12 }, (_, index) =>
    (index + 1).toString().padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, index) =>
    index.toString().padStart(2, "0")
  );

  return (
    <div className="w-full max-w-[541px] h-[120px] bg-white rounded-[20px] flex items-center justify-evenly p-2 md:p-4 space-x-4 md:space-x-6">
      {/* Hour Dropdown */}
      <select
        value={hour}
        onChange={(e) => setHour(e.target.value)}
        className="text-center text-[16px] md:text-[24px] font-medium border-none focus:outline-none w-1/4 bg-gray-100 p-2 rounded-lg shadow-md"
      >
        {hours.map((h) => (
          <option key={h} value={h}>
            {h}
          </option>
        ))}
      </select>

      {/* Separator ":" */}
      <span className="text-[16px] md:text-[24px] font-medium">:</span>

      {/* Minute Dropdown */}
      <select
        value={minute}
        onChange={(e) => setMinute(e.target.value)}
        className="text-center text-[16px] md:text-[24px] font-medium border-none focus:outline-none w-1/4 bg-gray-100 p-2 rounded-lg shadow-md"
      >
        {minutes.map((m) => (
          <option key={m} value={m}>
            {m}
          </option>
        ))}
      </select>

      {/* AM/PM Toggle with Arrows */}
      <div
        className="flex flex-col items-center space-y-1 cursor-pointer"
        onClick={togglePeriod}
      >
        {/* Arrow Icon above the AM/PM Text */}
        <img
          src={leftarrow}
          alt="Toggle AM/PM"
          className="w-4 h-4 md:w-4 md:h-3 rotate-90 mb-1" // Adjust rotation and margin to position above text
        />

        {/* AM/PM Text */}
        <span className="text-[16px] md:text-[20px] font-semibold">
          {period}
        </span>
      </div>
    </div>
  );
};

export default TimePicker;
